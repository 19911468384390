import axios from "axios";
import config from "@/common/config/config";
import { ClientRole, roleMapper } from "@/shared/security/role-mapper";
import { Nullable } from "@/common/utils/typescript-utilities";

const baseUrl = config.api.baseUrl;
const authRootUrl = `${baseUrl}/auth`;
const securityRootUrl = `${baseUrl}/security`;

const bearerTokenClientKey = "bearer_token";
const loginAsLocalStorageKey = "loginAsLocal";

export interface CurrentUserInfo {
  role: Nullable<ClientRole>;
  username: string;
  email: string;
  organisatieNaam: string;
}

export const securityService = {
  getBearerToken,
  validateBearerToken,
  loginWithBearer,
  loginLocalWithRoleAndVkboNummer,
  logout,
  logoutFromAcmIdm,
  getCurrentUserInfo,
  getAcmIdmLoginUrl,
  get hasBearerToken() {
    return localStorage.getItem(bearerTokenClientKey) !== null;
  },
  get bearerToken() {
    return localStorage.getItem(bearerTokenClientKey);
  },
};

function getAcmIdmLoginUrl() {
  return `${config.openId.auth.redirectUrl}?client_id=${config.openId.auth.clientId}&redirect_uri=${config.openId.auth.callbackUrl}&response_type=code&scope=openid+profile+vo+dv_rvv&response_mode=query`;
}

function getBearerToken(params: URLSearchParams) {
  const code = params.get("code") as string; // 'node'
  return axios.post(`${authRootUrl}/bearer`, {code: code});
}

async function validateBearerToken(token: string | null) {
  if (!token) return false;

  const loginAsLocal = localStorage.getItem(loginAsLocalStorageKey);
  if (!config.production && loginAsLocal === "true") {
    return await axios
      .get(`${securityRootUrl}/ValidateJwtToken/local`)
      .then((response) => response.data)
      .catch(() => false);
  } else {
    return await axios
      .get(`${securityRootUrl}/ValidateJwtToken`)
      .then((response) => response.data)
      .catch(() => false);
  }
}

function loginWithBearer(token: string) {
  localStorage.setItem(loginAsLocalStorageKey, "false");
  localStorage.setItem(bearerTokenClientKey, token);
}

function logout() {
  const loginAsLocal = localStorage.getItem(loginAsLocalStorageKey);
  if (!config.production && loginAsLocal === "true") {
    localStorage.removeItem(loginAsLocalStorageKey);
    localStorage.removeItem(bearerTokenClientKey);
    window.location.href = "/";
  } else {
    localStorage.removeItem(loginAsLocalStorageKey);
    localStorage.removeItem(bearerTokenClientKey);
    logoutFromAcmIdm(true);
  }
}

async function logoutFromAcmIdm(redirect: boolean) {
  if (redirect) {
    window.location.href = `${config.openId.logout.redirectUrl}?client_id=${config.openId.auth.clientId}&post_logout_redirect_uri=${config.baseUrl}`;
  } else {
    window.location.href = `${config.openId.logout.redirectUrl}?client_id=${config.openId.auth.clientId}`;
  }
}

async function loginLocalWithRoleAndVkboNummer(role: string, vkboNummer: string) {
  const params = { role, vkboNummer };
  await axios
    .get(securityRootUrl, { params })
    .then((response: any) => {
      localStorage.setItem(bearerTokenClientKey, response.data);
      localStorage.setItem(loginAsLocalStorageKey, "true");
    })
    .catch(() => {
      return;
    });
}

async function getCurrentUserInfo(): Promise<CurrentUserInfo | void> {
  // return await axios.get(`${authRootUrl}/bearer/inspect` ).then((response: any) => {
  return await axios
    .get(`${authRootUrl}/current-user-info`)
    .then((response: any) => {
      return {
        username: response.data.currentUserInfo.username,
        role: roleMapper.mapRole(response.data.currentUserInfo.role),
        email: response.data.currentUserInfo.email,
        organisatieNaam: response.data.currentUserInfo.organisatieNaam,
      };
    })
    .catch((error) => {
      console.log("ERROR:", error);
    });
}
