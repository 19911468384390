import { securityService } from "@/shared/security/security-service";
import { RouteConstants } from "@/router/router-constants";
import config from "@/common/config/config";

const widgetClient = (window as any).vl.widget.client;

function bindWidget(url: string, elementId: string) {
  widgetClient.bootstrap(url)
    .then((widget: any) => {
      return widget
        // Zoek het DOM-element met ID "global-header" welke we gaan
        // gebruiken om de widget aan de koppelen. Zodra men de widget
        // zijn "mount" gedrag triggered zal het DOM-element vervangen
        // worden maar niet verwijderd. Het "umount" gedrag zal het
        // originele DOM-element opnieuw herstellen.
        .setMountElement(document.getElementById(elementId))
        // Geef instructie aan de widget instantie om "mount" gedrag
        // uit te voeren.
        .mount();
    })
    .catch(console.error.bind(console));
}


export function initBurgerprofielHeaderAndFooter() {

  const widgetClient = (window as any).vl.widget.client;

  if (config.burgerprofiel?.headerWidgetUri) {
    bindWidget(config.burgerprofiel.headerWidgetUri, 'global-header');
  }

  if (config.burgerprofiel?.footerWidgetUri) {
    bindWidget(config.burgerprofiel.footerWidgetUri, 'global-footer');
  }

  // Capture any widget that is present or will be present on the webpage.
  widgetClient.capture(async function (widget: any) {
    // Only process the widget if widget is a global header.
    if (widget.getPluginTypeId() === 'global_header') {
      // Get the Citizen Profile Session extension from the global header widget.
      const session = await widget.getExtension('citizen_profile.session')
      // This variable is purely to indicate which values are allowed (true / false).
      const hasValidBearerToken = await securityService.validateBearerToken(securityService.bearerToken);
      // Inform the session extension about the current session state of the website.
      session.configure({
        active: hasValidBearerToken,
        endpoints: {
          loginUrl: securityService.getAcmIdmLoginUrl(),
          loginRedirectUrl: '',
          logoutUrl: RouteConstants.LogoutPage
        }
      });
    }
  });

}
